<template>
  <div>
    <!-- 头部 -->
    <Header val="yes"></Header>
    <!-- 进度条 -->
    <Progress :val="1"></Progress>
    <!-- 首页内容 -->
    <div class="neiron">
      <div class="left">
        <!-- 日历 -->
        <div class="calendar">
          <calendar @dateClick="dateClickFn"></calendar>

          <!--  -->
          <div style="display: none" class="tishi">
            <i>●</i> Not available for sale
          </div>
        </div>
        <!-- 票 数量 -->
        <div class="ticketNum">
          <!-- 标题 -->
          <div class="ticketNum-title">
            <p>Moominvalley Park 1-Day Pass</p>
            <el-popover
              popper-class="homePopover"
              placement="top-end"
              title="Moominvalley Park 1-Day Pass"
              trigger="hover"
            >
              <div class="mainDlg">
                <p>
                  • Refundable one day before the day of visit. For refund
                  please email
                  <a
                    style="color: #007bac; white-space: nowrap"
                    href="mailto:order@moominvalleypark-booking.com"
                    >order@moominvalleypark-booking.com</a
                  >
                </p>
                <p>
                  • Re-entering the park on a valid day is allowed. Please show
                  the verified ticket to the staff when re-entering.
                </p>
                <p>
                  • When guests enter the Park, click the E-ticket URL and the
                  E-ticket will be displayed on the page. Confirm your booking
                  information for the E-ticket, then press the "Confirm" button,
                  and show the verified ticket to the staff.
                </p>
                <p>
                  • When purchasing more than one ticket, each guest has to show
                  the verified ticket to the staff.
                </p>
                <p>
                  • Reselling tickets or providing any ticket for the purpose of
                  reselling to a third party is strictly prohibited whether it
                  is for profit or not.
                </p>
                <p>
                  • Tickets obtained by violating any of these prohibitions will
                  be void. Refunds of those tickets are not allowed.
                </p>
              </div>
              <p style="color: #12334c; cursor: pointer" slot="reference">
                <SvgIcon
                  iconClass="home_details"
                  style="font-size: 16px; cursor: pointer; margin-right: 2px"
                ></SvgIcon>
                <span>Details</span>
              </p>
            </el-popover>
          </div>
          <!-- 内容 -->
          <div class="nier">
            <div class="item" style="border-bottom: 1px solid #e8eaec">
              <div class="lft">
                <p>Adult (18+)</p>
                <div class="num"></div>
              </div>
              <div class="rght">
                <p class="price">
                  ￥{{
                    adultMaxNum
                      ? setSumPrice(
                          priceList.adult && priceList.adult.price,
                          1
                        ) || "-"
                      : "-"
                  }}
                </p>
                <div class="Adultnum">
                  <el-input-number
                    v-model="adultNum"
                    @change="handleChange"
                    :min="0"
                    :max="adultMaxNum"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </div>
            </div>
            <!-- 新增 -->
            <div class="item" style="border-bottom: 1px solid #e8eaec">
              <div class="lft">
                <p>Secondary Student (13~17)</p>
                <div class="num"></div>
              </div>
              <div class="rght">
                <p class="price">
                  ￥{{
                    studentMaxNum
                      ? setSumPrice(
                          priceList.student && priceList.student.price,
                          1
                        ) || "-"
                      : "-"
                  }}
                </p>
                <div class="Childnum">
                  <el-input-number
                    v-model="studentNum"
                    @change="handleChange"
                    :min="0"
                    :max="studentMaxNum"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="lft">
                <p>Child (4~12)</p>
                <div class="num"></div>
              </div>
              <div class="rght">
                <p class="price">
                  ￥{{
                    childMaxNum
                      ? setSumPrice(
                          priceList.child && priceList.child.price,
                          1
                        ) || "-"
                      : "-"
                  }}
                </p>
                <div class="Childnum">
                  <el-input-number
                    v-model="childNum"
                    @change="handleChange"
                    :min="0"
                    :max="childMaxNum"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 总价 按钮 -->
        <div class="totalPrice">
          <div class="totalPrice-left">Total</div>
          <div class="totalPrice-right">
            <div class="totPrice">
              ￥{{ setSumPrice(totalPrice, 1) || "-" }}
            </div>
            <el-button :disabled="!setSumPrice(totalPrice, 1)" @click="yesClick"
              >Continue</el-button
            >
          </div>
        </div>
      </div>
      <div class="right">
        <ul>
          <li @click="aLianjieFn2('service')">
            <p>Terms of Service</p>
            <i class="el-icon-arrow-right"></i>
          </li>
          <li @click="aLianjieFn2('privacy')">
            <p>Privacy Policy</p>
            <i class="el-icon-arrow-right"></i>
          </li>
          <!-- <li @click="aLianjieFn2('notice')">
            <p>Notice</p>
            <i class="el-icon-arrow-right"></i>
          </li> -->
          <li @click="aLianjieFn2('contact')">
            <p>Contact Us</p>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>
        <div class="right-pp">© MOOMIN All rights reserved.</div>
      </div>
    </div>

    <!-- 下一步 弹窗 -->
    <!-- <div class="nextDialog">
      <el-dialog
        :visible.sync="nextDialogBr"
        width="740px"
        :before-close="handleClose"
      >
        <div class="title">Moominvalley Park 1-Day Pass</div>
        <div class="mainDlg">
          <p>• Refundable three days before the day of visit.</p>
          <p>
            • Re-entering the park on a valid day is allowed. Please show the
            verified ticket to the staff when re-entering.
          </p>
          <p>
            • When guests enter the Park, click the E-ticket URL and the
            E-ticket will be displayed on the page. Confirm your booking
            information for the E-ticket, then press the "Confirm" button, and
            show the verified ticket to the staff.
          </p>
          <p>
            • When purchasing more than one ticket, each guest has to show the
            verified ticket to the staff.
          </p>
          <p>
            • Reselling tickets or providing any ticket for the purpose of
            reselling to a third party is strictly prohibited whether it is for
            profit or not.
          </p>
          <p>
            • Tickets obtained by violating any of these prohibitions will be
            void. Refunds of those tickets are not allowed.
          </p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="nextDialogBr = false">Cancel</el-button>
          <el-button type="primary" @click="yesClick">Continue</el-button>
        </span>
      </el-dialog>
    </div> -->

    <!-- cookies -->
    <div v-if="cookiesBuer != '0'" class="cookies">
      <div class="cookies-box">
        <div class="cookies-tit">Cookies will be used by the website</div>
        <div class="cookies-main">
          We use cookies, as well as third-party cookies, to improve the Site,
          evaluate our audience, enhance your experience.Please note that
          certain cookies we use are essential for the functioning of various
          parts of the website.
        </div>
        <el-button @click="cookiesBtn"> Accept </el-button>
      </div>
    </div>

    <!-- cookis 占位 -->
    <div v-if="cookiesBuer != '0'" class="placeholder-cook"></div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Progress from "@/components/progress.vue";
import calendar from "@/components/calendar.vue";
import SvgIcon from "@/components/SvgIcon.vue";

import { get_price } from "@/api/common.js";
export default {
  data() {
    return {
      nextDialogBr: false, // 下一步弹窗状态
      childMaxNum: 0, // 最大预定数
      adultMaxNum: 0, // 最大预定数
      studentMaxNum: 0, // 最大预定数
      priceList: [], // 票数，价格
      adultNum: 0, // 票数量
      childNum: 0, // 票数量
      studentNum: 0, // 票数量
      totalPrice: "", // 总价
      cookiesBuer: localStorage.getItem("cookiesBuer") || "1", //

      param: {
        date: "",
        adult: "",
        adultPrice: "",
        child: "",
        childPrice: "",
        student: "",
        studentPrice: "",
        totalPrice: "",
      },
    };
  },
  methods: {
    // handleClose(done) {
    //   done();
    // },
    // 询问框确定按钮
    yesClick() {
      // console.log("yes");
      this.nextDialogBr = false;
      localStorage.setItem("orderParam", JSON.stringify(this.param));
      this.$router.push({
        path: "/info",
      });
    },
    aLianjieFn2(val) {
      console.log(val);
      this.$router.push({ path: val });
    },
    // 日历时间点击事件
    dateClickFn(date) {
      console.log(date);
      this.param.date = date;
      // 获取价格
      this.getPriceFn(date);
    },
    // 下一步
    continueFn() {
      // console.log(this.param);
      this.nextDialogBr = true;
    },
    // 数量加减
    handleChange() {
      // console.log(this.priceList.child);
      // console.log(this.priceList.adult.price);
      // console.log(this.childNum);
      // console.log(this.adultNum);

      console.log(
        +(
          (this.priceList.child ? this.priceList.child.price : 0) *
          +this.childNum
        ),
        +(
          (this.priceList.adult ? this.priceList.adult.price : 0) *
          +this.adultNum
        )
      );
      // 计算总价
      this.totalPrice =
        +(
          (this.priceList.child ? this.priceList.child.price : 0) *
          +this.childNum
        ) +
        +(
          (this.priceList.adult ? this.priceList.adult.price : 0) *
          +this.adultNum
        ) +
        +(
          (this.priceList.student ? this.priceList.student.price : 0) *
          +this.studentNum
        );
      // console.log(this.totalPrice);

      this.param.adult = this.adultNum;
      this.param.child = this.childNum;
      this.param.student = this.studentNum;

      this.param.totalPrice = this.totalPrice;
    },
    // cookies点击事件
    cookiesBtn() {
      this.cookiesBuer = "0";
      localStorage.setItem("cookiesBuer", "0");
    },
    // 根据时间获取日期
    async getPriceFn(date) {
      let res = await get_price(date);
      this.priceList = res.data.data;
      console.log(res);
      console.log(this.priceList);

      // 没有库存操作
      if (!this.priceList.adult && !this.priceList.child && !this.priceList.student) {
        this.childNum = 0;
        this.priceList.child = null;
        this.adultNum = 0;
        this.priceList.adult = null;
        this.studentNum = 0;
        this.priceList.student = null;

        // 判断最大预定数
        this.childMaxNum = 0;
        this.adultMaxNum = 0;
        this.studentMaxNum = 0;
      } else if (!this.priceList.adult) {
        console.log("进来了");
        this.adultNum = 0;
        this.priceList.adult = null;

        this.param.childPrice = this.priceList.child.price;
        this.param.studentPrice = this.priceList.student.price;
        // 判断最大预定数
        this.adultMaxNum = 0;
        this.childMaxNum =
          this.priceList.child.num > 4 ? 4 : this.priceList.child.num;
        this.studentMaxNum =
          this.priceList.student.num > 4 ? 4 : this.priceList.student.num;
      } else if (!this.priceList.child) {
        this.childNum = 0;
        this.priceList.child = null;

        this.param.adultPrice = this.priceList.adult.price;
        this.param.studentPrice = this.priceList.student.price;
        // 判断最大预定数
        this.childMaxNum = 0;
        this.adultMaxNum =
          this.priceList.adult.num > 4 ? 4 : this.priceList.adult.num;
        this.studentMaxNum =
          this.priceList.student.num > 4 ? 4 : this.priceList.student.num;
      } else if (!this.priceList.student) {
        this.studentNum = 0;
        this.priceList.student = null;

        this.param.adultPrice = this.priceList.adult.price;
        this.param.childPrice = this.priceList.child.price;
        // 判断最大预定数
        this.studentMaxNum = 0;
        this.adultMaxNum =
          this.priceList.adult.num > 4 ? 4 : this.priceList.adult.num;
        this.childMaxNum =
          this.priceList.child.num > 4 ? 4 : this.priceList.child.num;
      } else {
        this.param.childPrice = this.priceList.child.price;
        this.param.adultPrice = this.priceList.adult.price;
        this.param.studentPrice = this.priceList.student.price;
        // 判断最大预定数
        this.adultMaxNum =
          this.priceList.adult.num > 4 ? 4 : this.priceList.adult.num;
        this.childMaxNum =
          this.priceList.child.num > 4 ? 4 : this.priceList.child.num;
        this.studentMaxNum =
          this.priceList.student.num > 4 ? 4 : this.priceList.student.num;
      }
      this.handleChange();
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != "/m_home") {
        this.$router.replace("/m_home");
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != "/home") {
        this.$router.replace("/home");
      }
    }
  },
  created() {
    this.cookiesBuer = localStorage.getItem("cookiesBuer") || "1";
  },
  components: {
    Header,
    Progress,
    calendar,
    SvgIcon,
  },
};
</script>

<style lang="less" scoped>
// 下一步弹窗
.nextDialog {
  /deep/.el-button + .el-button {
    margin-left: 20px;
  }
  /deep/.el-dialog__header {
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 0;
    font-size: 15px;
    margin-bottom: 30px;
    color: #12334c;
    .title {
      word-break: break-word;
      font-size: 18px;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
  /deep/.el-dialog__footer {
    padding: 0;
  }
  /deep/.el-button {
    width: 159px;
    height: 42px;
    font-size: 16px;
  }
  /deep/.dialog-footer {
    // width: 348px;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;
  }

  /deep/.el-dialog {
    padding: 40px;
    border-radius: 12px;
    margin-top: 25vh !important;
  }
  /deep/.el-icon-close:before {
    content: "";
  }
  /deep/.el-button--default {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #12334c;
  }
  /deep/.el-button--primary {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #fff;
    background-color: #12334c;
  }
}
.neiron {
  width: 1030px;
  margin: 20px auto 30px;
  padding: 0 15px;
  display: flex;
  align-items: flex-start;
  .left {
    width: 745px;
    margin-right: 20px;
    .calendar {
      background-color: #fff;
      // height: 427px;
      border-radius: 12px;
      padding: 40px;
      .tishi {
        font-weight: 400;
        font-size: 14px;
        color: #12334c;
        i {
          color: #b2bac1;
        }
      }
    }
    .ticketNum {
      background-color: #fff;
      // height: 256px;
      margin-top: 20px;
      border-radius: 12px;
      padding: 40px;
      font-size: 18px;
      .ticketNum-title {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #12334c;
      }
      .nier {
        color: #12334c;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 82px;
          .rght {
            display: flex;
            align-items: center;
            .price {
              margin-right: 20px;
            }
          }
        }
      }
    }

    .totalPrice {
      background-color: #fff;
      height: 122px;
      margin-top: 20px;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px;
      color: #12334c;

      .totalPrice-left {
        font-size: 18px;
        font-weight: bold;
      }

      .totalPrice-right {
        display: flex;
        align-items: center;
        font-size: 20px;
        /deep/.el-button {
          margin-left: 20px;
          background-color: #12334c;
          color: #fff;
          border-radius: 7px;
          font-size: 16px;
          width: 142px;
        }
      }
    }
  }

  .right {
    background-color: #fff;
    width: 235px;
    // height: 178px;
    border-radius: 12px;
    padding: 11px 0;
    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        padding: 0 20px;
        cursor: pointer;
        font-size: 12px;
        // font-weight: bold;
      }
    }

    .right-pp {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      color: #b2bac1;
      // text-align: center;
      padding: 0 20px;
      white-space: nowrap;
    }
  }
}

// 数量加减样式

/deep/.el-input-number {
  width: 142px;
  height: 42px;
  line-height: 42px;
}
/deep/.el-input-number .el-input__inner {
  padding-left: 0px;
  padding-right: 0px;
  width: 42px;
  height: 43px;
  margin-left: 50px;
  border-radius: 7px;
  margin-top: 1px;
  font-size: 24px;
  color: unset;
}
/deep/.el-input-number__decrease,
/deep/.el-input-number__increase {
  width: 42px;
  height: 42px;
  border: 1px solid #b2bac1;
  border-radius: 7px;
  color: #12334c;
  // font-size: 20px;
  background-color: #fff;
  border-color: #12334c;
  .el-icon-minus,
  .el-icon-plus {
    color: #12334c;
    font-weight: 700;
  }
  // .el-icon-minus,
  // .el-icon-plus {
  //   color: #b2bac1;
  // }

  &.is-disabled {
    border: 1px solid #b2bac1;
    .el-icon-minus,
    .el-icon-plus {
      color: #b2bac1;
    }
  }
}

/deep/.el-input-number__decrease:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled),
/deep/.el-input-number__increase:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled) {
  border-color: #dcdfe6;
}

// cookies
.cookies {
  width: 100%;
  background-color: #002039;
  position: fixed;
  bottom: 0;
  color: #fff;
  padding: 30px 15px;
  z-index: 999;
  opacity: 0.9;
  .cookies-box {
    max-width: 1030px;
    margin: 0 auto;
    padding: 0 15px;

    .cookies-tit {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 16px;
    }
    .cookies-main {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 14px;
    }
    .el-button {
      width: 119px;
      height: 37px;
      border-radius: 7px;
      color: #12334c;
      background: #fff;
      border: 0;
      font-weight: 700;
      &:hover {
        color: #12334c;
      }
    }
  }
}
.placeholder-cook {
  height: 179px;
  background-color: #fff;
}
</style>